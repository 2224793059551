/* InfoCard.css */

.info-card {
  position: absolute;
  top: 0;
  width: 320px; /* Set width to 500px */
  height: 350px; /* Set height to 300px */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 30px; /* Set border radius to 30px */
  background-color: rgba(255, 255, 255, 0.8); /* Background with blur effect */
  padding: 10px;
  box-sizing: border-box;
  display: none;
  z-index: 1;
  overflow: hidden; /* Add overflow: hidden to prevent content overflow */
}

/* Align to the right by default */
.info-card.right {
  left: 100%;
  transform: translateX(10px); /* Add a small gap from the edge */
}

/* Align to the left if .left class is present */
.info-card.left {
  left: auto;
  right: 100%;
  transform: translateX(calc(-100% + 10px)); /* Adjusted for alignment with the right edge */
}

.anime-card-container:hover .info-card {
  display: block;
}
