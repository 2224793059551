/* Community.css */

body {
    margin: 0;
    padding: 0;
  }
  
  .community-container {
    background-color: #000000; /* Dark background color */
    color: orangered; /* Orange text color */
    padding: 20px;
    height: 100%; /* 100% of the viewport height */
    display: flex;
    flex-direction: column;
  }
  
  .community-heading {
    color: orangered; 
  }
  
  .welcome-message {
    color: orangered; /* Orange text color for welcome message */
  }
  
  .messages-container {
    flex: 1; /* Allow messages container to grow and take remaining space */
    margin-top: 20px;
    overflow-y: auto; /* Enable vertical scrollbar when content overflows */
    max-height: calc(100vh - 200px); /* Adjust as needed, considering space for input and button */
  }
  
  /* Style for Webkit Scrollbar */
  .messages-container::-webkit-scrollbar {
    width: 10px;
  }
  
  .messages-container::-webkit-scrollbar-thumb {
    background-color: #FF6347; /* Orange color for thumb */
    border-radius: 5px;
  }
  
  .messages-container::-webkit-scrollbar-track {
    background-color: #000000; /* Dark background color for track */
    border-radius: 5px;
  }
  
.message-card {
  background-color: #000000;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(169, 168, 168, 0.1);
  border: 1px solid #FFFFFF; /* Add white border around each message box */
}
  .message-text {
    color: orangered; /* Orange text color for messages */
  }
  
  .reply-button {
    background-color: orangered; /* Orange background for reply button */
    color: #333; /* Dark text color for reply button */
    padding: 5px 10px;
    border: none;
    border-radius: 3px;
    margin-top: 5px;
    cursor: pointer;
  }
  
  .reply-list {
    list-style-type: none;
    padding: 0;
    margin-top: 5px;
  }
  
  .reply-item {
    color: orangered; /* Orange text color for replies */
  }
  
  .new-message-container {
    margin-top: auto; /* Push the message input and button to the bottom */
  }
  
  .message-input {
    width: calc(100% - 20px); /* Adjust width, considering padding */
    padding: 10px;
    border: 1px solid orangered;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .reply-info {
    color: orangered; /* Orange text color for reply info */
  }
  
  .send-button {
    background-color: orangered; /* Orange background for send button */
    color: #333; /* Dark text color for send button */
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .send-button:hover {
    background-color: #FF6347; /* Darker orange on hover for send button */
  }
  