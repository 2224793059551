/* View.css */

.view-container {
  display: flex;
  flex-direction: column; /* Change to column layout on small screens */
  align-items: stretch;
}

.table-container,
.video-container {
  padding: 20px; /* Include padding in width and height calculations */
}

@media (min-width: 768px) {
  /* Adjust the breakpoint (768px) based on your design */
  .view-container {
    flex-direction: row; /* Use row layout on larger screens */
    align-items: flex-start;
  }

 
}

/* Keep the existing styles for other elements */

ul {
  list-style: none;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

.ep-button {
  padding: 8px 0; /* Adjust padding as needed */
  width: 40px; /* Set constant width */
  margin-right: 10px;
  text-align: center;
  cursor: pointer;
}

.filler-button {
  background-color: orange;
  width: 40px;
  text-align: center;
  color: white;
}

/* Responsive styles for the video player */
@media (max-width: 767px) {
  .video-container {
    width: 100%; /* Make the video container full width on smaller screens */
    margin-top: 20px; /* Add some space between the video and table on smaller screens */
  }

  .table-container {
    margin-bottom: 20px; /* Add some space below the table on smaller screens */
  }
}
