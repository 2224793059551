.sign-in-container {
  background-image: url('../images/img.jpg');
  background-size: cover;
  min-height: 100vh; /* You can adjust this value to occupy more vertical space */
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-in-form {
  width: 400px; /* Increase the width of the form */
  padding: 60px; /* Increase the padding to make the card taller */
  background-color: black;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.sign-in-form h2 {
  color: orangered;
  text-align: center;
}

.sign-in-form label {
  display: block;
  margin-bottom: 8px;
  color: white;
}

.sign-in-form input[type="text"],
.sign-in-form input[type="password"] {
  width: 100%;
  padding: 10px; /* Increase padding for better visibility and spacing */
  margin-bottom: 20px; /* Increase margin bottom for better spacing */
  box-sizing: border-box;
  background-color: black;
  color: white;
  border: 1px solid rgb(220, 220, 220);
  border-radius: 5px;
  outline: none;
}

.sign-in-form button[type="submit"] {
  width: 100%;
  padding: 12px; /* Increase padding for better visibility and spacing */
  background-color: orangered;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sign-in-form button[type="submit"]:hover {
  background-color: orangered;
}

.sign-in-form p {
  color: white;
  cursor: pointer;
}
