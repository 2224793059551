.signup-container {
  background-image: url('../images/img.jpg');
  background-size: cover;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signup-form {
  width: 400px;
  padding: 60px;
  background-color: black;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.signup-form h2 {
  color: orangered;
  text-align: center;
}

.signup-form label {
  display: block;
  margin-bottom: 8px;
  color: white;
}

.signup-form input[type="text"],
.signup-form input[type="email"],
.signup-form input[type="password"],
.signup-form input[type="tel"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  background-color: black;
  color: white;
  border: 1px solid rgb(220, 220, 220);
  border-radius: 5px;
  outline: none;
}

.signup-form button[type="submit"] {
  width: 100%;
  padding: 12px;
  background-color: orangered;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.signup-form button[type="submit"]:hover {
  background-color: orangered;
}
.sign-up-form p {
  color: white;
  cursor: pointer;
}
