body {
    margin: 0px;
    padding: 0px;
    background-color: black;
    
  }

  /* Add this to your CSS file or style section */
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Set height to full viewport height */
}
::-webkit-scrollbar {
  width: 8px;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: orangered;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background-color: rgba(200, 200, 200, 0.9);
  border-radius: 20px;
  width: 10px;
}
