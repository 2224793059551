.MDBFooter {
  position: fixed;
  
  bottom: 0;
  width: 100%;
  background-color: dark;
  color: white;
}

/* Add the following styles if needed */
.MDBContainer {
  padding-top: 20px; /* Adjust as needed */
  padding-bottom: 20px; /* Adjust as needed */
}