/* Navbar.css */

.navbar-container {
  background-color: black;
  padding: 5px;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.logo {
  font-size: 1.5rem;
}

.nav-links {
  list-style: none;
  margin: 0;
}

.nav-links ul {
  display: flex;
  justify-content: center; /* Move links to the center */
  list-style-type: none;
}

.nav-links li {
  margin-right: 20px;
}

.nav-links a {
  text-decoration: none;
  color: orangered;
}

.auth-buttons {
  display: flex;
}

.auth-buttons button {
  margin-right: 0px;
  background-color: orangered;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
}

.auth-buttons button:hover {
  background-color: orangered;
  text-decoration: none;
}

.auth-buttons a {
  margin-right: 10px;
  text-decoration: none;
  color: inherit;
}

.auth-buttons a:hover {
  text-decoration: none;
}

.search-bar {
  display: flex;
}

.search-input {
  flex: 1; /* Make the input field take up remaining space */
  border: none;
  padding: 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.search-button {
  background-color: orangered;
  color: white;
  border: none;
  padding: 10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  cursor: pointer;
}

.search-button:hover {
  background-color: darkorange;
}

.burger-icon {
  display: none;
  cursor: pointer;
  color: white;
  font-size: 1.5rem;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .nav-links {
    display: none; /* Hide the links by default */
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 60px; /* Adjust this value as needed */
    left: 0;
    width: 100%;
    background-color: black;
    z-index: 1;
  }

  .nav-links.active {
    display: flex; /* Show the links when active */
  }

  .nav-links li {
    margin-right: 0;
    margin-bottom: 5px;
  }

  .burger-icon {
    display: block;
  }

  .auth-buttons {
    order: 1; /* Move auth buttons to the top for smaller screens */
  }

  .search-bar {
    order: 2; /* Move search to the top for smaller screens */
  }
}


/* Add this to your existing CSS */

.burger-icon {
  font-size: 1.5rem;
  background: transparent;
  border: none;
  color: white;
  cursor: pointer;
  padding: 10px;
  display: none; /* Hide by default on larger screens */
}

@media (max-width: 768px) {
  .burger-icon {
    display: block; /* Show on smaller screens */
  }
}

/* Optional: Add animation for a more interactive feel */
.burger-icon:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transition: background-color 0.3s ease-in-out;
}

.nav-links.active li:not(:last-child) {
  margin-bottom: 5px;
}

/* Optional: Add styling to the separator */
.nav-links.active li:not(:last-child)::after {
  content: '|';
  margin-left: 5px;
  margin-right: 5px;
  color: orangered; /* Color of the separator */
}
