/* Add this CSS to your styles (Result.css) */


  
  h1 {
    color: orangered;
    text-align: center;
    font-family: 'Arial, sans-serif';
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  .d-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  button {
    background-color: orangered;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    margin: 5px;
    cursor: pointer;
  }
  
  button:disabled {
    background-color: gray;
    cursor: not-allowed;
  }
  
  .page-info {
    color: orangered;
    text-align: center;
    margin-top: 10px;
  }
  