/* Search.css */

.search-bar {
  position: relative;
}
.search-options form {
  display: flex;
  /* Add any additional styling for the form container if needed */
}
.filter-button {
  background-color: orangered;
  color: white;
  border: none;
  padding: 10px;
  position: absolute;
  top: -5px;
  right: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  cursor: pointer;
}

.filter-button:hover {
  background-color: darkorange;
}

.search-options {
  position: relative;
  width: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.search-input {
  flex: 1;
  border: 1px solid #333;
  padding: 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: #fff;
  background-color: #333;
}

/* Additional styles for search results */
.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #333; /* Dark background for search results */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 5px 5px;
  max-height: 300px;
  overflow-y: auto; /* Enable vertical scrolling */
  color: #fff; /* Adjust text color */
}

/* Hide scrollbar, but keep the scroll functionality */
.search-results::-webkit-scrollbar {
  width: 12px;
}

.search-results::-webkit-scrollbar-thumb {
  background-color: #666; /* Dark color for the scrollbar thumb */
  border-radius: 10px; /* Round the scrollbar thumb */
}

.search-results::-webkit-scrollbar-track {
  background-color: #333; /* Dark color for the scrollbar track */
  border-radius: 10px; /* Round the scrollbar track */
}

.search-results div {
  padding: 15px;
  display: flex;
  align-items: center;
  position: relative; /* Add relative positioning for child elements */
}

.search-results img {
  max-width: 70px; /* Increase the size of the image */
  height: auto;
  margin-right: 15px; /* Wider margin on the right side */
  border-radius: 8px; /* Adjust border-radius for a rounded look */
  box-shadow: 0 0 8px rgba(255, 165, 0, 0.7); /* Darker and more prominent shadow */
}

/* Enhance theme in search results text */
.search-results a {
  color: #ff8c00; /* Orange color for links */
  text-decoration: none; /* Remove default underline */
  font-weight: bold; /* Highlight name */
  display: block; /* Ensure each link is on a new line */
}

.search-results a:hover {
  color: #ff4500; /* Darker orange color on hover */
}

.search-results .details {
  flex: 1;
  display: flex;
  flex-direction: column; /* Display child elements in a column */
}

.search-results .details p {
  margin: 0;
  font-size: 14px;
  color: #ddd; /* Light gray color for additional information */
  white-space: nowrap; /* Prevent line breaks within each piece of information */
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Display ellipsis (...) for overflow content */
}

/* Adjust styles for name and jname */
.search-results .details p:first-child {
  font-weight: bold; /* Highlight name */
}

.search-results .details p:nth-child(2) {
  font-style: italic; /* Italicize jname */
}

.search-results .result-item {
  border: none;
  border-top: 2px solid #555; /* Adjust border color */
  margin: 10px 0; /* Adjust margin for spacing */
}