/* Add this to your existing styles or CSS file */

.category-buttons {
    display: flex;
    gap: 10px;
  }
  
  .category-buttons button {
    width: 80px;
    border-radius: 5px;
  }

  .info-page-container {
    margin-top: 5rem;
    width: 85%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }