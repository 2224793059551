/* AnimeInfo.css */

.anime-info-container {
    display: flex;
    margin: 0 auto;
    padding: 20px;
    background-color: rgba(200, 200, 200, 0.3);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .anime-info-container img {
    max-width: 300px;
    height: auto;
    border-radius: 5px;
    margin-right: 20px;
  }
  
  .anime-info-details {
    flex: 1;
  }
  
  .anime-info-container h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .anime-info-container p {
    margin-bottom: 15px;
  }
  
  .anime-info-container div {
    background-color: #000000;
    border-radius: 5px;
    padding: 10px;
    color: white;
  }
  
  .anime-info-container div p {
    margin: 5px 0;
  }
  
  .loading {
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
  }
  
  .error {
    color: #ff0000;
    text-align: center;
    font-size: 18px;
    margin-top: 20px;
  }
  