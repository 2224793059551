/* AnimeCard.css */

.anime-card-container {
  position: relative;
}

.anime-card {
    position: relative;
    overflow: visible;
    border: 1px solid #000;
    border-radius: 10px;
    transition: transform 0.3s, box-shadow 0.3s;
    background-color: #000;
    color: #FF4500;
    box-shadow: 0 4px 8px rgba(255, 255, 255, 0.5); /* Initial light shadow */
  }
  
  .anime-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Increase shadow on hover */
  }
  
  .anime-card img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 10px 10px 0 0;
  }
  
  .card-details {
    padding: 10px;
  }
  
  .card-details h5 {
    margin: 0;
    font-size: 1.2rem;
    font-weight: bold;
  }
  
  .card-details p {
    margin: 8px 0 0;
    font-size: 0.9rem;
    color: #555;
  }
  
  .btn-show-info {
    font-size: 0.8rem;
    color: #007BFF;
    text-decoration: none;
    cursor: pointer;
  }
  
  .btn-show-info:hover {
    text-decoration: underline;
  }
  
  